import React, { useEffect, useState } from "react";
import { BackgroundOverlay, Container, InfoWrapper } from "./elements";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Img from "gatsby-image";
import { H4BigStrong, H5Big } from "../Typography";
import HomepageTitle from "../HomepageTitle";
import { HomepageButton } from "../HomepageButton/elements";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { navigate } from "gatsby";
import { Overlay } from "../CommunityCardHolder";

const HomepageFood = ({ foodParticipants }) => {
  const [overlayOn, setOverlayOn] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState(null);
  const [randomArray, setRandomArray] = useState([]);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < foodParticipants?.length; i++) {
      let rand = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (
        foodParticipants[i].participantType?.length &&
        foodParticipants[i].participantType[0] === "Food"
      ) {
        rand = -1;
      }
      temp.push(rand);
    }
    setRandomArray(temp);
  }, []);

  return (
    <Container>
      <HomepageTitle
        title={`On the Food \n Side of Life`}
        buttonText="SHOW ALL"
        buttonSlug="participants?type=food"
      />
      <Swiper
        className="mySwiper"
        direction="horizontal"
        spaceBetween={20}
        centeredSlides={true}
        centerInsufficientSlides={true}
        modules={[Autoplay]}
        autoplay={{
          enabled: true,
          delay: 2000,
          // pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        speed={2500}
        loop={true}
        slidesPerView={3}
      >
        {foodParticipants?.map((item) => (
          <SwiperSlide
            key={item.id}
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setCurrentlySelected(item);
              setOverlayOn(true);
            }}
          >
            <Img
              fixed={
                item.headerImage?.length
                  ? item.headerImage[0]?.localFile?.childImageSharp?.fixed
                  : null
              }
              style={{ width: "100%", height: "100%" }}
            />
            <BackgroundOverlay />
            <InfoWrapper>
              <H4BigStrong style={{ color: "var(--green)" }}>
                {item.title || ""}
              </H4BigStrong>
              <H5Big style={{ color: "var(--white)" }}>
                {item.participantLocation?.length
                  ? item.participantLocation[0]?.title
                  : ""}
              </H5Big>
            </InfoWrapper>
          </SwiperSlide>
        ))}
      </Swiper>

      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HomepageButton
            onClick={() => {
              navigate(`participants?type=food`);
            }}
          >
            SHOW ALL
          </HomepageButton>
        </div>
      </AnimationOnScroll>

      {overlayOn ? (
        <Overlay
          communityMember={currentlySelected}
          closeThis={() => {
            setOverlayOn(false);
          }}
          randomNumber={
            randomArray[foodParticipants.indexOf(currentlySelected)]
          }
        />
      ) : null}
    </Container>
  );
};

export default HomepageFood;
