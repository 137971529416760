import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white);
  padding: 50px 0;
  > p {
    padding: 0 310px;
  }
  > h3 {
    padding: 0 100px;
  }
  @media (max-width: 767px) {
    gap: 16px;
    padding: 20px 0 25px;
    > p {
      padding: 20px 20px 0;
      font-size: 28px !important;
    }
    > h3 {
      padding: 0 20px 20px;
      font-size: 21px !important;
    }
  }
`;
