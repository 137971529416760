import styled from "styled-components";

export const Container = styled.div`
  padding: 30px 0;
  background-color: var(--white);

  .swiper {
    width: 100%;
    max-width: 1440px;
    height: 100px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    object-fit: contain !important;
  }

  @media (max-width: 767px) {
    padding: 16px 0;
    .swiper {
      height: unset;
      a {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .gatsby-image-wrapper {
      width: 102px !important;
      height: 35px !important;
    }
    .swiper-slide {
      width: 125px !important;
      // margin-right: 40px !important;
    }
  }
`;
