import React, { useEffect, useRef } from "react";
import Layout from "./../components/Layout";
import Img from "gatsby-image";
import HomepageHero from "../components/HomepageHero";
import HomepageIntro from "../components/HomepageIntro";
import HomepageTheme from "../components/HomepageTheme";
import HomepageInfo from "../components/HomepageInfo";
import HomepageMusic from "../components/HomepageMusic";
import HomepageParticipants from "../components/HomepageParticipants";
import HomepageSponsors from "../components/HomepageSponsors";
import { graphql, navigate, useStaticQuery } from "gatsby";
import HomepageFood from "../components/HomepageFood";
import HomepageAudioPlayer from "../components/HomepageAudioPlayer";

const Homepage = () => {
  const homepageQuery = useStaticQuery(graphql`
    {
      craftQafHomepageQafHomepageEntry {
        mp4Video {
          url
        }
        webmVideo {
          url
        }
        heroImage {
          url
        }
        bannerImage {
          ... on Craft_qafAssets_Asset {
            localFile {
              childImageSharp {
                fluid(maxWidth: 960, quality: 100) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        introTitle
        introDescription
        introButtonText
        introButtonLink {
          slug
        }
        homepageEventsBackground {
          ... on Craft_qafAssets_Asset {
            localFile {
              childImageSharp {
                fixed(width: 1440, fit: COVER, quality: 100) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        homepageEventsTitle
        homepageEvents {
          ... on Craft_homepageEvents_events_BlockType {
            id
            firstLine
            secondLine
            image {
              ... on Craft_qafAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(width: 250, height: 300, fit: COVER, quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        counterSection {
          title
          number
        }
        homepageMap {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        homepageSponsors {
          ... on Craft_homepageSponsors_sponsor_BlockType {
            id
            logo {
              ... on Craft_qafAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            logoLink
          }
        }
        homepageSoundcloud
      }
      events: allCraftQafEventsQafEventEntry {
        nodes {
          id
          title
          isStaging
          eventEndDateTime
          eventStartDateTime
          eventShortDescription
          qafEventSubTheme
          qafEventLocation {
            title
            ... on Craft_participants_default_Entry {
              id
              participantLocation {
                id
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              participantType
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
          slug
          eventType
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(fit: COVER, width: 172) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      musicEvents: allCraftQafEventsQafEventEntry(
        filter: { eventType: { eq: "music" } }
      ) {
        edges {
          node {
            id
            title
            eventStartDateTime
            eventEndDateTime
            slug
            qafEventLocation {
              title
            }
            headerImage: qafEventHeaderImage {
              ... on Craft_eventsAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100, width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      foodParticipants: allCraftParticipantsDefaultEntry(
        filter: { isDraft: { eq: false }, participantType: { eq: "Food" } }
      ) {
        edges {
          node {
            id
            communityMemberSlug
            participantType
            communityMemberWebsite
            communityMemberEmailAddress
            subtitle
            communityMemberOpeningHours
            participantLocation {
              id
              title
            }
            communityMemberDescription
            communityMemberContactDetails {
              method
              value
            }
            slug
            title
            headerImage: qafParticipantHeaderImage {
              ... on Craft_communityAssets_Asset {
                localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 295, height: 295, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      participants: allCraftParticipantsDefaultEntry(
        filter: { isDraft: { eq: false } }
      ) {
        nodes {
          id
          communityMemberSlug
          participantType
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            id
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 295) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const data = homepageQuery?.craftQafHomepageQafHomepageEntry;
  const events = homepageQuery?.events?.nodes?.filter((item) => {
    const thisDate = new Date(
      item.eventEndDateTime || item.eventStartDateTime || null
    );
    return thisDate >= now;
  });

  const musicEventsRef = useRef({
    musicEvents: homepageQuery?.musicEvents?.edges
      ?.sort(() => 0.5 - Math.random())
      .slice(0, 3)
      ?.map((item) => item.node)
      .filter((item) => {
        const thisDate = new Date(
          item.eventEndDateTime || item.eventStartDateTime || null
        );
        return thisDate >= now;
      }),
  });
  const participants = homepageQuery?.participants?.nodes;
  const foodParticipants = homepageQuery?.foodParticipants?.edges?.map(
    (item) => item.node
  );

  const mp4Url = data?.mp4Video?.length ? data?.mp4Video[0]?.url : null;
  const webmUrl = data?.webmVideo?.length ? data?.webmVideo[0]?.url : null;
  const heroImageUrl = data?.heroImage?.length ? data?.heroImage[0]?.url : null;
  const bannerImage = data?.bannerImage?.length
    ? data?.bannerImage[0]?.localFile?.childImageSharp?.fluid
    : null;

  const introTitle = data?.introTitle || null;
  const introDescription = data?.introDescription || null;
  const introButtonText = data?.introButtonText || null;
  const introButtonSlug = data?.introButtonLink?.length
    ? data?.introButtonLink[0]?.slug
    : null;

  const homepageEventsBackground = data?.homepageEventsBackground?.length
    ? data?.homepageEventsBackground[0]?.localFile?.childImageSharp?.fixed
    : null;
  const homepageEventsTitle = data?.homepageEventsTitle || null;
  const homepageEvents = data?.homepageEvents || null;

  const counterList = data?.counterSection || null;

  const homepageMap = data?.homepageMap?.length
    ? data?.homepageMap[0]?.localFile?.childImageSharp?.fluid
    : null;

  const homepageSponsors = data?.homepageSponsors || null;

  const homepageSoundcloud = data?.homepageSoundcloud || null;

  useEffect(() => {
    for (let i = 0; i < foodParticipants?.length; i++) {
      const relatedE = events.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter(
              (item) => item?.participantType && item?.participantType?.length
            )
          : [];

        return checkedArray.find(
          (item) => item?.id === foodParticipants[i]?.id
        );
      });
      foodParticipants[i].relatedEvents = relatedE;
    }

    for (let i = 0; i < participants?.length; i++) {
      const relatedE = events.filter((e) => {
        const checkedArray = e?.qafEventLocation?.length
          ? e?.qafEventLocation.filter(
              (item) => item?.participantType && item?.participantType?.length
            )
          : [];

        return checkedArray.find((item) => item?.id === participants[i]?.id);
      });
      participants[i].relatedEvents = relatedE;
    }
  }, []);

  return (
    <Layout backgroundColor={"var(--black)"} skipHeader headerBlack={true}>
      <HomepageHero
        mp4Url={mp4Url}
        webmUrl={webmUrl}
        heroImageUrl={heroImageUrl}
        bannerImage={bannerImage}
      />

      <HomepageIntro
        introTitle={introTitle}
        introDescription={introDescription}
        introButtonText={introButtonText}
        introButtonSlug={introButtonSlug}
      />

      <HomepageTheme
        backgroundImage={homepageEventsBackground}
        title={homepageEventsTitle}
        themes={homepageEvents}
      />

      <HomepageInfo counterList={counterList} />

      <HomepageMusic events={musicEventsRef.current.musicEvents} />

      <div
        style={{
          margin: "auto",
          width: "100%",
          height: "100%",
          maxHeight: "879px",
          maxWidth: "1440px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/programme/map")}
      >
        <Img
          fluid={homepageMap}
          style={{
            maxHeight: "879px",
            maxWidth: "1440px",
          }}
        />
      </div>

      <HomepageFood foodParticipants={foodParticipants} />

      <HomepageSponsors sponsors={homepageSponsors} />

      <HomepageParticipants participants={participants} />

      <HomepageAudioPlayer homepageSoundcloud={homepageSoundcloud} />
    </Layout>
  );
};

export default Homepage;
