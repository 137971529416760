import React, { useEffect, useState } from "react";
import { Container } from "./elements";
import HomepageTitle from "../HomepageTitle";
import CommunityCardHolder from "../CommunityCardHolder";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { HomepageButton } from "../HomepageButton/elements";
import { navigate } from "gatsby";

const HomepageParticipants = ({ participants }) => {
  const currentCards = participants.slice(0, 9);

  const [overlayOn, setOverlayOn] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState(null);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [randomArray, setRandomArray] = useState([]);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < participants.length; i++) {
      let rand = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (participants[i].participantType[0] === "Food") {
        rand = -1;
      }
      temp.push(rand);
    }
    setRandomArray(temp);
  }, []);

  return (
    <Container>
      <HomepageTitle
        title="Participants"
        buttonText="SHOW ALL"
        buttonSlug="participants"
      />

      <CommunityCardHolder
        cards={currentCards.filter((x) =>
          currentTypes.length === 0 ? true : checkTypes(x.participantType)
        )}
        closeOverlay={false}
        randomArray={randomArray}
        cardsOrigin={participants}
      />

      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HomepageButton
            onClick={() => {
              navigate(`participants`);
            }}
          >
            SHOW ALL
          </HomepageButton>
        </div>
      </AnimationOnScroll>

      {overlayOn ? (
        <Overlay
          communityMember={currentlySelected}
          closeThis={() => {
            setOverlayOn(false);
          }}
          randomNumber={
            randomArray[foodParticipants.indexOf(currentlySelected)]
          }
        />
      ) : null}
    </Container>
  );
};

export default HomepageParticipants;
