import React, { Fragment, useEffect, useState } from "react";
import { H6 } from "../Typography";
import { Container, PlayPause } from "./elements";
import ReactPlayer from "react-player/soundcloud";
import Marquee from "react-fast-marquee";

const HomepageAudioPlayer = ({ homepageSoundcloud }) => {
  const [play, setPlay] = useState(false);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const checkOffset = () => {
      let socialFloat = document.querySelector("#homepage-audio-wrapper");
      let footer = document.querySelector("#footer");
      const getRectTop = (e) => {
        return e.getBoundingClientRect().top;
      };
      if (
        getRectTop(socialFloat) +
          document.body.scrollTop +
          socialFloat.offsetHeight >=
        getRectTop(footer) + document.body.scrollTop - 55
      ) {
        socialFloat.style.position = "absolute";
        // socialFloat.style.bottom = "0";
      }
      if (
        document.body.scrollTop + window.innerHeight <
        getRectTop(footer) + document.body.scrollTop
      ) {
        socialFloat.style.position = "fixed";
        // socialFloat.style.bottom = "55px";
      }
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", checkOffset);
    }

    const iframe = document.querySelector("iframe");
    const soundcloudWidget = SC.Widget(iframe);
    soundcloudWidget.bind(SC.Widget.Events.READY, () => {
      soundcloudWidget.getCurrentSound((currentSound) => {
        setTitle(currentSound?.title);
      });
      soundcloudWidget.bind(SC.Widget.Events.PLAY, () => {
        soundcloudWidget.getCurrentSound((currentSound) => {
          setTitle(currentSound?.title);
        });
      });
    });

    return () => {
      window.removeEventListener("scroll", checkOffset);
    };
  }, []);

  return (
    <Fragment>
      <Container id="homepage-audio-wrapper">
        <PlayPause
          onClick={() => {
            homepageSoundcloud && setPlay(!play);
          }}
        >
          {/* PLAY */}
          {!play && (
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.5 6L0 12V0L10.5 6Z" fill="white" />
            </svg>
          )}
          {/* PAUSE */}
          {play && (
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="2" height="10" fill="white" />
              <rect x="5" width="2" height="10" fill="white" />
            </svg>
          )}
        </PlayPause>

        {homepageSoundcloud && title ? (
          <Marquee gradient={false} direction="right">
            <H6
              id="artist-name"
              style={{
                marginRight: "10px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </H6>
          </Marquee>
        ) : (
          <H6
            id="artist-name"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            ...
          </H6>
        )}
      </Container>

      <ReactPlayer
        url={homepageSoundcloud}
        style={{ display: "none" }}
        playing={play}
        controls
        loop
      />
    </Fragment>
  );
};

export default HomepageAudioPlayer;
