import React from "react";
import {
  getFormatShortDayNoTimeZone,
  getTime,
  formatTime,
  getTwoDifferenceDayFormatNoTimeZone,
  getFormatTwoShortDayNoTimeZone,
} from "../../modules/time";
import ArticleHeroImage from "../ArticleHeroImage";
import HomepageTitle from "../HomepageTitle";
import { Container } from "./elements";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { HomepageButton } from "../HomepageButton/elements";
import { navigate } from "gatsby";

const HomepageMusic = ({ events }) => {
  const handleDate = (start, end) => {
    return new Date(start)?.getMonth() !== new Date(end)?.getMonth() ||
      !start ||
      !end
      ? getTwoDifferenceDayFormatNoTimeZone(start, end)
      : new Date(start)?.getDate() === new Date(end)?.getDate()
      ? getFormatShortDayNoTimeZone(start, true)
      : getFormatTwoShortDayNoTimeZone(start, end, true);
  };

  const handleTime = (start, end) => {
    return end ? `${formatTime(getTime(start))}_${formatTime(getTime(end))}` : formatTime(getTime(start));
  };

  const images = events?.map((item) => {
    return {
      fixed: item.headerImage?.length
        ? item.headerImage[0]?.localFile?.childImageSharp?.fixed
        : null,
      data: {
        title: item.title,
        date: handleDate(item.eventStartDateTime, item.eventEndDateTime),
        time: handleTime(item.eventStartDateTime, item.eventEndDateTime),
        location: item.qafEventLocation?.length
          ? item.qafEventLocation[0]?.title
          : "",
      },
      link: `/event/${item?.slug}`
    };
  });

  return (
    <Container>
      <HomepageTitle
        title="Line-up"
        buttonText="ALL ARTISTS"
        buttonSlug="programme?type=music"
        clearTheme
      />
      <AnimationOnScroll
        animatePreScroll={true}
        animateIn="animate__zoomIn"
        // animateOut="animate__zoomOut"
        duration={2}
        offset={40}
        animateOnce
      >
        <ArticleHeroImage
          color={"var(--white)"}
          carouselImages={images}
          useCarousel
          showDots={false}
          imagesAreUrls
          imagesAreClickable
        />
      </AnimationOnScroll>
      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HomepageButton
            onClick={() => {
              localStorage.removeItem("theme");
              navigate(`/programme?type=music`);
            }}
          >
            ALL ARTISTS
          </HomepageButton>
        </div>
      </AnimationOnScroll>
    </Container>
  );
};

export default HomepageMusic;
