import { navigate } from "gatsby";
import React, { Fragment, useState } from "react";
import { HomepageButton } from "../HomepageButton/elements";
import { H3SmallMedium } from "../Typography";
import { Container } from "./elements";
import AnimatedText from "react-animated-text-content";
import ScrollTrigger from "react-scroll-trigger";

const HomepageIntro = ({
  introTitle,
  introDescription,
  introButtonText,
  introButtonSlug,
}) => {
  const [effect, setEffect] = useState(false);

  return (
    <Fragment>
      <div style={{ height: "1px" }} />
      <ScrollTrigger
        onEnter={() => setEffect(true)}
        onExit={() => setEffect(false)}
      >
        <Container>
          {introTitle ? (
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="bounce"
              interval={0.06}
              duration={0.8}
              tag="p"
              style={{
                fontWeight: 600,
                fontSize: "42px",
                lineHeight: 1.25,
                margin: 0,
              }}
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              {introTitle}
            </AnimatedText>
          ) : null}

          {introDescription ? (
            <H3SmallMedium>{introDescription}</H3SmallMedium>
          ) : null}

          {introButtonText && introButtonSlug ? (
            <HomepageButton
              onClick={() => {
                if (typeof window !== "undefined") {
                  localStorage.removeItem("theme");
                }
                navigate(`/${introButtonSlug}`);
              }}
            >
              {introButtonText}
            </HomepageButton>
          ) : null}
        </Container>
      </ScrollTrigger>
    </Fragment>
  );
};

export default HomepageIntro;
