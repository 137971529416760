import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 0;
  background-color: var(--black);

  .swiper {
    min-width: 1440px;
    width: 100%;
    height: 460px;
    margin: 0 0 0 100px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    object-fit: cover !important;
  }
  
  .btn-mobile-wrapper {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 36px 0;
    .btn-mobile-wrapper {
      margin: 36px 20px 0;
      display: block;
      & > div {
        & > button {
          flex: 1;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .swiper {
      min-width: 754px;
      height: 228px;
      margin: 0 0 0 20px;
    }
  }
`;

export const InfoWrapper = styled.div`
  position: absolute;
  left: 24px;
  bottom: 18px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: unset !important;
  min-width: unset !important;
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 35.22%
  );
`;
