import styled from "styled-components";

export const ArticleHeroImageDiv = styled.div`
  --color: ${props => props.color || "var(--purple)"};
  width: var(--width);
  height: 607px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  /* padding: 50px var(--outerMargin) 25px var(--outerMargin); */
  padding: 0 0 25px;
  background-color: var(--black);
  color: var(--color);
  & h5 {
    margin-top: 15px;
    & + h5 {
      margin-top: 0;
    }
  }
  & h2 {
    margin-top: 50px;
    max-width: 1000px;
    text-align: center;
    color: var(--color);
    & + h2 {
      margin-top: 0;
    }
  }
  & div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
  }
  & > div:first-of-type {
    position: absolute;
    /* bottom: 25px; */
    /* height: 540px; */
    z-index: 1;
    overflow: visible;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      ${props => (props.useCarousel ? "height: 250px;" : "bottom: 0;")}
      top: 0;
      /* background-image: ${props =>
        props.isDark
          ? "linear-gradient(var(--white), rgba(255, 255, 255, 0))"
          : props.useCarousel
          ? "linear-gradient(180deg, #101820 0%, rgba(16, 24, 32, 0) 100%);"
          : "linear-gradient(var(--black), rgba(16, 24, 32, 0))"}; */
      z-index: 2;
      pointer-events: none;
    }
    & img {
      position: relative;
      width: 100%;
      height: auto;
      object-fit: cover;
      z-index: 2;
    }
    & + div {
      z-index: 2;
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 20px;

    width: 100%;
    height: 212px;

    background: #101820;

    --outerMargin: 10px;
    & h2 {
      font-size: 36px;
      line-height: 48px;
      max-width: calc(100% - 60px);
    }
  }
  @media (max-width: 900px) {
    height: 200px;
  }
`;
