import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  left: 100px;
  bottom: 55px;
  width: 191px;
  height: 32px;
  z-index: 100;
  color: var(--white);
  background-color: var(--black);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 0 12px;
  border: 1.25px solid var(--white);
  @media only screen and (max-width: 767px) {
    left: 20px;
    bottom: 20px;
  }
`;

export const PlayPause = styled.div`
  width: 11px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
