import styled from "styled-components";

export const HomepageButton = styled.button`
  background-color: var(--green);
  color: var(--black);
  border: none;
  padding: 5px 24px;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 150%;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.3s;
  }
`;
