import styled from "styled-components";

export const Container = styled.div`
  height: 139px;
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  margin: auto;
  @media (max-width: 767px) {
    height: unset;
  }
`;

export const Item = styled.div`
  flex: 1 0 calc(20% - 1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--green);
  > h2 {
    color: var(--white);
  }
  > h3 {
    color: var(--black);
  }
  @media (max-width: 767px) {
    height: 108px;
    flex: 1 0 calc(33% - 1px);
    > h2 {
      font-size: 36px;
    }
    > h3 {
      font-size: 15px;
    }
  }
`;
