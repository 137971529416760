import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 0 30px;
  .btn-mobile-wrapper {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 36px 0;
    .btn-mobile-wrapper {
      margin: 16px 20px 0;
      display: block;
      & > div {
        & > button {
          flex: 1;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
`;
