import styled from "styled-components";

export const Container = styled.div`
  background-color: transparent;
  height: 100vh;
  position: relative;
  display: flex;
  padding: 240px 80px 120px;
  flex-direction: column;
  justify-content: center;
  color: var(--green);
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  color: var(--green);
`;

export const Video = styled.video`
  background-image: ${(props) =>
    props.backgroundImage ? `url(${props.backgroundImage})` : "unset"};
  background-color: var(--black);
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  /* z-index: -100; */
  display: inline-block;
  vertical-align: baseline;
`;

export const BannerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 27px;
  @media only screen and (min-width: 576px) {
    padding-right: 70px;
  }
`;
