import React from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";
import {
  BackgroundOverlay,
  Card,
  CardContent,
  CardList,
  Container,
} from "./elements";
import { H2SmallStrong, H3SmallMedium, H3Strong } from "../Typography";
import { AnimationOnScroll } from "react-animation-on-scroll";

const HomepageTheme = ({ backgroundImage, title, themes }) => {
  const handleChooseTheme = (index) => {
    if (typeof window !== "undefined") {
      switch (index) {
        case 0:
          localStorage.setItem("theme", "cyborg");
          break;
        case 1:
          localStorage.setItem("theme", "nature");
          break;
        case 2:
          localStorage.setItem("theme", "childhood");
          break;
        default:
          localStorage.removeItem("theme");
      }
    }
    navigate("/programme");
  };

  const handleColor = (index) => {
    switch (index) {
      case 0:
        return "var(--red)";
      case 1:
        return "var(--yellow)";
      case 2:
        return "#B5A8F6";
      default:
        return "var(--green)";
    }
  };

  return (
    <Container>
      <Img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: -2,
        }}
        fixed={backgroundImage}
      />

      <BackgroundOverlay />

      {title ? (
        <AnimationOnScroll
          animateOnce={true}
          animatePreScroll={true}
          animateIn="animate__fadeInDown"
          animateOut="animate__fadeOutUp"
          offset={50}
          // duration={1.5}
        >
          <H3Strong style={{ textAlign: "center" }}>{title}</H3Strong>
        </AnimationOnScroll>
      ) : null}

      <CardList>
        {themes?.map((item, index) => {
          return (
            <AnimationOnScroll
              key={item.id}
              animatePreScroll={true}
              animateIn={
                index === 0
                  ? "animate__fadeInLeft"
                  : index === 1
                  ? "animate__fadeInUp"
                  : "animate__fadeInRight"
              }
              animateOut={
                index === 0
                  ? "animate__fadeOutLeft"
                  : index === 1
                  ? "animate__fadeOut"
                  : "animate__fadeOutRight"
              }
              // duration={1.5}
              offset={100}
            >
              <Card>
                <CardContent
                  style={{ cursor: "pointer" }}
                  onClick={() => handleChooseTheme(index)}
                >
                  <H3SmallMedium>{item.firstLine}</H3SmallMedium>
                  <H2SmallStrong style={{ color: handleColor(index) }}>
                    {item.secondLine}
                  </H2SmallStrong>
                </CardContent>
                <div
                  className="image-wrapper"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleChooseTheme(index)}
                >
                  <Img
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                    }}
                    fixed={
                      item.image?.length
                        ? item.image[0]?.localFile?.childImageSharp?.fixed
                        : null
                    }
                  />
                </div>
              </Card>
            </AnimationOnScroll>
          );
        })}
      </CardList>
    </Container>
  );
};

export default HomepageTheme;
