import React from "react";
import { HomepageButton } from "../HomepageButton/elements";
import { H2BigStrong } from "../Typography";
import { TitleWrapper } from "./elements";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { AnimationOnScroll } from "react-animation-on-scroll";

const HomepageTitle = ({ title, buttonText, buttonSlug, clearTheme }) => {
  return (
    <TitleWrapper>
      <AnimationOnScroll
        animateIn="animate__fadeInLeft"
        animateOut="animate__fadeOutLeft"
        duration={0.75}
        offset={100}
      >
        <H2BigStrong style={{ whiteSpace: "pre-line" }}>{title}</H2BigStrong>
      </AnimationOnScroll>
      <AnimationOnScroll
        className="btn-desktop-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HomepageButton
            onClick={() => {
              if (clearTheme) {
                if (typeof window !== "undefined") {
                  localStorage.removeItem("theme");
                }
                navigate(`/${buttonSlug}`);
              } else {
                navigate(`/${buttonSlug}`);
              }
            }}
          >
            {buttonText}
          </HomepageButton>
        </div>
      </AnimationOnScroll>
    </TitleWrapper>
  );
};

export default HomepageTitle;

HomepageTitle.propsType = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
};
